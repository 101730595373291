<template>
  <alchemy-app-content-host>
    <template v-slot:body>
      <div
        v-if="branding"
        id="loginHost"
        class="py-4 d-flex flex-column h-100"
        :style="loginBackgroundStyle"
      >
        <div class="container">
          <alchemy-login-widget
            :openIdProviders="displayableOpenIdProviders"
            storeModule="alchemyLogin"
            :forgottenPassword="forgottenPassword"
            :openIdBusy="openIdBusy"
            v-on:loggedIn="onLogin($event)"
            v-on:openIdLogin="openIdConnect($event)"
          />
        </div>
      </div>
    </template>
  </alchemy-app-content-host>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { AlchemyLoginWidget } from "@encapto/alchemy-login-widget";
import { SET_SESSION } from "../store/mutations";
import {
  BRANDING,
  OPEN_ID_PROVIDERS,
  REDIRECT_URL,
  SSO_LOGIN,
  LOGIN_CHALLENGE,
  DEFAULT_LOGIN_BACKGROUND
} from "../store/getters";
import { OPEN_ID_CONNECT, ACCEPT_HYDRA_LOGIN, CONFIGURE_BRANDING } from "../store/actions";

const STORE_MODULE = "alchemyLogin";
const SET_LOGIN_PATH = "SET_LOGIN_PATH";

export default {
  name: "login",
  components: {
    AlchemyLoginWidget
  },
  props: {
    loginPath: {
      type: String,
      required: true
    },
    forgottenPassword: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      storeModule: STORE_MODULE,
      openIdBusy: false
    };
  },
  computed: {
    ...mapGetters({
      branding: BRANDING,
      openIdProviders: OPEN_ID_PROVIDERS,
      redirectUrl: REDIRECT_URL,
      isSSO: SSO_LOGIN,
      loginChallenge: LOGIN_CHALLENGE,
      defaultLoginBackground: DEFAULT_LOGIN_BACKGROUND
    }),
    loginBackgroundStyle() {
      return `background-image: url(${
        this.branding.loginBackgroundUrl || this.defaultLoginBackground
      }); background-repeat: no-repeat; background-size: cover`;
    },
    displayableOpenIdProviders() {
      return this.openIdProviders.filter(p =>
        _.get(p, ["configuration", "openId", "showOnLoginPage"], true)
      );
    }
  },
  methods: {
    onLogin(event) {
      const session = event.result.data;

      console.log("Session!", session);
      // save session to store
      this.$store.commit(SET_SESSION, session);

      if (this.isSSO) {
        // no need to wait for branding promise
        // we're about to head off elsewhere anyway
        this.$store
          .dispatch(ACCEPT_HYDRA_LOGIN, {
            challenge: this.loginChallenge,
            userUuid: session.userUuid
          })
          .then(url => {
            window.location.href = url;
          });
      } else {
        this.$store.dispatch(CONFIGURE_BRANDING, {
          brandingEntity: session.brandingName || "encapto"
        });
      }
    },
    openIdConnect(event) {
      this.openIdBusy = true;

      // only need to catch because if successful this will redirect elsewhere..
      return this.$store.dispatch(OPEN_ID_CONNECT, { provider: event.provider }).catch(e => {
        this.openIdBusy = false;
        throw e;
      });
    }
  },
  mounted() {
    this.openIdBusy = true;
    // this.$store.dispatch(FETCH_OPEN_ID_PROVIDERS).finally(() => (this.openIdBusy = false)); // FIXME: Add back when fully implemented
    this.$store.commit(
      [this.storeModule, AlchemyLoginWidget.name, SET_LOGIN_PATH].join("/"),
      this.loginPath
    );
  }
};
</script>
<style lang="scss">
#loginHost {
  justify-content: flex-start;
  .container {
    max-width: 800px;
  }
}
@media screen and (min-width: 540px) {
  #loginHost {
    justify-content: center;
  }
}
@media screen and (max-width: 992px) {
  #loginHost {
    .container {
      max-width: 540px;
    }
  }
}
</style>
