import _ from "lodash";
import moment from "moment";
import { chartUtils, storeUtils } from "@encapto/alchemy-gui";
import { toSeoFriendlyUrl } from "../lib-api/utils";

import {
  getLoadingCandidateEntities,
  routeVisibility,
  calculateVisibleRoutes,
  checkRouteExists,
  checkFunctionExists
} from "../lib-api/view-utils";

export const LOGGED_IN = "LOGGED_IN";
export const HAS_ERROR = "HAS_ERROR";
export const ERROR_STATUS_CODE = "ERROR_STATUS_CODE";

export const SELECTED_PERIOD = "SELECTED_PERIOD";
export const PERIOD = "PERIOD";

export const TODAY = "TODAY";
export const LAST_24_HOURS = "LAST_24_HOURS";
export const LAST_7_DAYS = "LAST_7_DAYS";
export const LAST_30_DAYS = "LAST_30_DAYS";
export const LAST_12_MONTHS = "LAST_12_MONTHS";
export const CUSTOM_PERIOD = "CUSTOM_PERIOD";
export const BRANDING = "BRANDING";
export const OPEN_ID_PROVIDERS = "OPEN_ID_PROVIDERS";
export const ROUTES = "ROUTES";
export const OWNING_ENTITY = "OWNING_ENTITY";
export const SELECTED_ROOT_ENTITY = "SELECTED_ROOT_ENTITY";
export const CANDIDATE_ENTITY_TYPES = "CANDIDATE_ENTITY_TYPES";
export const AVAILABLE_ENTITY_TYPES = "AVAILABLE_ENTITY_TYPES";
export const USER_PROFILE = "USER_PROFILE";
export const ACTIVE_ROUTE = "ACTIVE_ROUTE";

export const VISIBLE_NAV_ITEMS = "VISIBLE_NAV_ITEMS";
export const VISIBLE_ROUTES = "VISIBLE_ROUTES";
export const ROUTER_PARAMS = "ROUTER_PARAMS";
export const ROUTER_QUERY = "ROUTER_QUERY";

export const FIRST_NAVIGABLE_ROUTE = "FIRST_NAVIGABLE_ROUTE";
export const REDIRECT_URL = "REDIRECT_URL";
export const IS_IMPERSONATING = "IS_IMPERSONATING";
export const IMPERSONATOR = "IMPERSONATOR";
export const IS_MERCHANT_USER = "IS_MERCHANT_USER";
export const SESSION_BRANDING_NAME = "SESSION_BRANDING_NAME";

export const SSO_LOGIN = "SSO_LOGIN";
export const LOGIN_CHALLENGE = "LOGIN_CHALLENGE";

export const OAUTH_CLIENT = "OAUTH_CLIENT";
export const SELECTED_OAUTH_ENTITY = "SELECTED_OAUTH_ENTITY";
export const PAGE_BUSY = "PAGE_BUSY";
export const IS_COMPOSITE_ENTITY = "IS_COMPOSITE_ENTITY";
export const DEFAULT_LOGIN_BACKGROUND = "DEFAULT_LOGIN_BACKGROUND";
export const NEW_FEATURES = "NEW_FEATURES";
export const SUPPORT_LINKS = "SUPPORT_LINKS";
export const ALL_SITES_VISIBLE = "ALL_SITES_VISIBLE";
export const ANNOUNCEMENTS = "ANNOUNCEMENTS";
export const ROUTE_ROOT_REFERENCE = "ROUTE_ROOT_REFERENCE";

// this should help with caching requests.
const roundNowToMin = () => moment().startOf("minute");

const getPeriod = (start, end) => ({
  start: chartUtils.dateToUnixTime(start.toDate()),
  end: chartUtils.dateToUnixTime(end.toDate())
});

const { createSimpleGetter } = storeUtils;

export const getters = {
  [LOGGED_IN](state) {
    return !_.isEmpty(state.session);
  },
  [HAS_ERROR](state) {
    return !_.isEmpty(state.currentError);
  },
  [ERROR_STATUS_CODE](state) {
    return _.get(state, "currentError.response.status");
  },
  [TODAY]() {
    const period = getPeriod(moment().startOf("day"), moment().endOf("day"));
    period.timerange = "hour";
    return period;
  },
  [LAST_24_HOURS]() {
    const period = getPeriod(roundNowToMin().subtract(1, "day"), roundNowToMin());
    period.timerange = "hour";
    return period;
  },
  [LAST_7_DAYS]() {
    const period = getPeriod(moment().subtract(7, "day").startOf("hour"), roundNowToMin());
    period.timerange = "day";
    return period;
  },
  [LAST_30_DAYS]() {
    const period = getPeriod(moment().subtract(30, "day").startOf("hour"), roundNowToMin());
    period.timerange = "day";
    return period;
  },
  [LAST_12_MONTHS]() {
    const period = getPeriod(moment().subtract(12, "months").startOf("hour"), roundNowToMin());
    period.timerange = "day";
    return period;
  },
  ...createSimpleGetter(BRANDING, "branding"),
  ...createSimpleGetter(OPEN_ID_PROVIDERS, "openIdProviders"),
  ...createSimpleGetter(ROUTES, "session.routes", []),
  ...createSimpleGetter(NEW_FEATURES, "session.user.smbPreferences.newFeatures", {}),
  ...createSimpleGetter(CUSTOM_PERIOD, "dashboard.customDates"),
  [PERIOD](_state, getter) {
    // getter[SELECTED_PERIOD] is a string like "TODAY", "LAST_24_HOURS"
    // this returns the getter for that value, e.g. getter["LAST_24_HOURS"]
    return getter[getter[SELECTED_PERIOD]];
  },
  ...createSimpleGetter(SELECTED_PERIOD, "dashboard.period"),
  [SELECTED_ROOT_ENTITY](state, getter) {
    const sre = _.get(state, "selectedRootEntity.uuid")
      ? state.selectedRootEntity
      : getter[OWNING_ENTITY];
    return sre;
  },
  ...createSimpleGetter(ROUTE_ROOT_REFERENCE, "routeRootReference"),
  ...createSimpleGetter(AVAILABLE_ENTITY_TYPES, "availableEntityTypes", []),
  ...createSimpleGetter(OWNING_ENTITY, "session.rootEntity"),
  [IS_COMPOSITE_ENTITY](_state, getter) {
    const isComposite = _.get(getter[SELECTED_ROOT_ENTITY], "type") === "company";
    return isComposite;
  },
  [IS_MERCHANT_USER](state) {
    return _.get(state, "session.type") === "merchant";
  },
  ...createSimpleGetter(SESSION_BRANDING_NAME, "session.brandingName"),
  [IS_IMPERSONATING](_state, getter) {
    return getter[IMPERSONATOR] !== undefined;
  },
  ...createSimpleGetter(IMPERSONATOR, "session.data.impersonator"),
  ...createSimpleGetter(USER_PROFILE, "session.user", {}),
  ...createSimpleGetter(ACTIVE_ROUTE, "activeRoute"),
  [VISIBLE_ROUTES](state, getter) {
    const sessionRoutes = getter[ROUTES];
    const isMerchantUser = getter[IS_MERCHANT_USER];
    const isComposite = getter[IS_COMPOSITE_ENTITY];
    const owningEntity = getter[OWNING_ENTITY];
    const availableEntityTypes = getter[AVAILABLE_ENTITY_TYPES];
    const sessionFunctions = _.get(state, "session.permissions.functions", {});
    const visibleRoutes = calculateVisibleRoutes({
      sessionRoutes,
      isMerchantUser,
      isComposite,
      owningEntity,
      availableEntityTypes,
      sessionFunctions,
      getters: getter
    });
    return [...visibleRoutes, "no-access"];
  },
  [VISIBLE_NAV_ITEMS](state, getter) {
    const visibleRoutes = getter[VISIBLE_ROUTES];
    const activeRoute = getter[ACTIVE_ROUTE];
    return state.availableRoutes
      .filter(r => visibleRoutes.includes(_.get(r, "name", _.get(r, "children[0].name"))))
      .filter(r => !!r.label) // visible routes should have labels and icons
      .map(r => {
        let children = _.get(r, "children", []).filter(c => visibleRoutes.includes(c.name)); // visible child routes should have labels.

        const active = r.name === activeRoute || children.map(c => c.name).includes(activeRoute);
        if (active) {
          children = children.map(c => ({
            ...c,
            active: c.name === activeRoute || _.get(c, "grandChildren", []).includes(activeRoute)
          }));
        }
        return {
          ...r,
          name: _.get(r, "name", _.get(r, "children[0].name")),
          children: children.filter(c => !!c.label),
          active
        };
      });
  },
  [ALL_SITES_VISIBLE](state, getter) {
    // only show all sites if there is a valid route available.
    const sessionRoutes = getter[ROUTES];
    const isMerchantUser = getter[IS_MERCHANT_USER];
    const owningEntity = getter[OWNING_ENTITY];
    const availableEntityTypes = _.get(state, "availableOwnerTypes", []);
    const sessionFunctions = _.get(state, "session.permissions.functions", {});
    const visibleRoutes = calculateVisibleRoutes({
      sessionRoutes,
      isMerchantUser,
      isComposite: true, // force composite true.
      owningEntity,
      availableEntityTypes,
      sessionFunctions,
      getters: getter
    });
    return !!visibleRoutes.length;
  },
  [FIRST_NAVIGABLE_ROUTE](state, getter) {
    const visibleRoutes = getter[VISIBLE_ROUTES];
    return state.availableRoutes.find(r => _.includes(visibleRoutes, r.name));
  },
  [REDIRECT_URL](state, getter) {
    const routerParams = getter[ROUTER_PARAMS] || {};
    // if the redirect was to another brand, forget about it.
    const rUrl =
      _.isEmpty(state.redirect) ||
      _.get(state, "redirect.params.brandingEntity") !== routerParams.brandingEntity
        ? getter[FIRST_NAVIGABLE_ROUTE].path
            .replace(":brandingEntity", routerParams.brandingEntity)
            .replace(":siteName", routerParams.siteName)
            .replace(":siteReference", routerParams.siteReference)
        : state.redirect.fullPath;
    return rUrl;
  },
  [ROUTER_PARAMS](state, getter) {
    const selectedRootEntity = getter[SELECTED_ROOT_ENTITY];
    return {
      brandingEntity: state.brandingEntity,
      siteName: toSeoFriendlyUrl(
        _.get(selectedRootEntity, "configuration.name", _.get(selectedRootEntity, "name", ""))
      ),
      siteReference: toSeoFriendlyUrl(_.get(selectedRootEntity, "reference", ""))
    };
  },
  ...createSimpleGetter(ROUTER_QUERY, "routerQuery"),
  [CANDIDATE_ENTITY_TYPES](state, getter) {
    const sessionRoutes = getter[ROUTES];
    const sessionFunctions = _.get(state, "session.permissions.functions", {});
    const candidateEntityTypes = sessionRoutes
      .filter(routeName => checkRouteExists({ route: routeVisibility[routeName], routeName }))
      .filter(routeName =>
        checkFunctionExists({ route: routeVisibility[routeName], routeName, sessionFunctions })
      )
      .reduce(
        (acc, routeName) =>
          [].concat(acc, _.get(routeVisibility, [routeName, "candidateTypes"], [])),
        []
      );
    const uniqueTypes = new Set(candidateEntityTypes);
    return [...uniqueTypes];
  },
  [SSO_LOGIN](state) {
    return !!state.loginChallenge;
  },
  ...createSimpleGetter(LOGIN_CHALLENGE, "loginChallenge"),
  ...createSimpleGetter(OAUTH_CLIENT, "oauthClient"),
  ...createSimpleGetter(SELECTED_OAUTH_ENTITY, "oauthEntity"),
  [PAGE_BUSY](state) {
    const getAvailableTypesBusy = _.get(
      state,
      ["busy", "TRY_GET_AVAILABLE_ENTITY_TYPES", "busy"],
      false
    );
    const getEntityMapBusy = _.get(state, ["busy", "TRY_GET_ENTITY_TYPE_MAP", "busy"], false);
    return getAvailableTypesBusy || getEntityMapBusy || getLoadingCandidateEntities(state);
  },
  [DEFAULT_LOGIN_BACKGROUND]() {
    return "https://images.unsplash.com/photo-1556740772-1a741367b93e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80";
  },
  ...createSimpleGetter(SUPPORT_LINKS, "supportLinks"),
  ...createSimpleGetter(ANNOUNCEMENTS, "announcements")
};
