import { fetchApi, storeUtils } from "@encapto/alchemy-gui";
import _ from "lodash";
import moment from "moment";
import {
  SET_SESSION,
  SET_REPORT_CUSTOM_DATES,
  SET_BRANDING,
  SET_BRANDING_ENTITY,
  SET_OPEN_ID_PROVIDERS,
  SET_REDIRECT,
  SET_OAUTH_CLIENT,
  SET_LOGIN_CHALLENGE,
  SET_SELECTED_ROOT_ENTITY,
  SET_SELECTED_OAUTH_ENTITY,
  SET_REDIRECTING,
  SET_SUPPORT_LINKS,
  SET_AVAILABLE_ENTITY_TYPES,
  SET_CLUSTER_FEATURES,
  SET_AVAILABLE_OWNER_TYPES,
  SET_ANNOUNCEMENTS,
  CLEAR_ANNOUNCEMENT
} from "./mutations";

import {
  BRANDING,
  LOGGED_IN,
  SELECTED_ROOT_ENTITY,
  OAUTH_CLIENT,
  CANDIDATE_ENTITY_TYPES,
  IS_COMPOSITE_ENTITY,
  OWNING_ENTITY,
  ACTIVE_ROUTE,
  ROUTER_PARAMS
} from "./getters";

export const INIT_SESSION = "INIT_SESSION";
export const REFRESH_SESSION = "REFRESH_SESSION";
export const LOG_EVENT = "LOG_EVENT";

export const FETCH_HYDRA_LOGIN_CHALLENGE = "FETCH_HYDRA_LOGIN_CHALLENGE";
export const ACCEPT_HYDRA_LOGIN = "ACCEPT_HYDRA_LOGIN";
export const REJECT_HYDRA_LOGIN = "REJECT_HYDRA_LOGIN";

export const FETCH_HYDRA_CONSENT_CHALLENGE = "FETCH_HYDRA_CONSENT_CHALLENGE";
export const ACCEPT_HYDRA_CONSENT = "ACCEPT_HYDRA_CONSENT";
export const REJECT_HYDRA_CONSENT = "REJECT_HYDRA_CONSENT";

export const FETCH_OPEN_ID_PROVIDERS = "FETCH_OPEN_ID_PROVIDERS";
export const FIND_OPEN_ID_PROVIDER = "FIND_OPEN_ID_PROVIDER";
export const OPEN_ID_CONNECT = "OPEN_ID_CONNECT";
export const OPEN_ID_AUTHORISE = "OPEN_ID_AUTHORISE";

export const CONFIGURE_BRANDING = "CONFIGURE_BRANDING";
export const CONFIGURE_UPDATED_BRANDING = "CONFIGURE_UPDATED_BRANDING";
export const SIGN_OUT = "SIGN_OUT";
export const TRY_UNIMPERSONATE = "TRY_UNIMPERSONATE";
export const CLEAR_SESSION = "CLEAR_SESSION";
export const TRY_SET_CUSTOM_DATE_RANGE = "TRY_SET_CUSTOM_DATE_RANGE";
export const TRY_SET_FAVICON = "TRY_SET_FAVICON";
export const TRY_GET_AVAILABLE_ENTITY_TYPES = "TRY_GET_AVAILABLE_ENTITY_TYPES";
export const TRY_GET_AVAILABLE_OWNER_TYPES = "TRY_GET_AVAILABLE_OWNER_TYPES";
export const TRY_GET_SUPPORT_LINKS = "TRY_GET_SUPPORT_LINKS";
export const TRY_GET_ENTITY_TYPE_MAP = "TRY_GET_ENTITY_TYPE_MAP";
export const TRY_GET_CLUSTER_FEATURES = "TRY_GET_CLUSTER_FEATURES";

export const UPDATE_ROUTER_QUERY_PARAMS = "UPDATE_ROUTER_QUERY_PARAMS";
export const TRY_SUBMIT_USER_FEEDBACK = "TRY_SUBMIT_USER_FEEDBACK";
export const TRY_GET_ANNOUNCEMENTS = "TRY_GET_ANNOUNCEMENTS";
export const TRY_HIDE_ANNOUNCEMENT = "TRY_HIDE_ANNOUNCEMENT";
export const TRY_LIST_CAMERAS = "TRY_LIST_CAMERAS";

export const NAV_TO_SITE = "NAV_TO_SITE";

let announcementsCacheBust = 1;

const getProviderRedirectUrl = (provider, state, subscriptionUuid) => {
  const loginSuccessRedirectTo = _.isEmpty(state.redirect)
    ? `/${state.brandingEntity}`
    : state.redirect.fullPath;
  console.log("branding entity", state.brandingEntity);
  return fetchApi.callEntityFunction(provider.uuid, "openid-auth-get-login-url", {
    payload: {
      nonce: crypto.randomUUID(),
      subscriptionUuid,
      browserState: { brandingEntity: state.brandingEntity, loginSuccessRedirectTo }
    }
  });
};

export const actions = {
  [LOG_EVENT]({ getters }, payload) {
    return fetchApi.callEntityFunction(getters[SELECTED_ROOT_ENTITY].uuid, "log-event", {
      payload
    });
  },
  [INIT_SESSION]({ commit, dispatch }, { reference }) {
    return (
      fetchApi
        .callFunction("get-session", { payload: { reference }, logErrors: false })
        .then(result => {
          commit(SET_SESSION, result);
          return result;
        })
        // eslint-disable-next-line consistent-return
        .catch(e => {
          console.log(e);
          if (!e.response || e.response.status !== 404) {
            console.error(
              `API Service Error: ${
                e.response.status
              } - POST: get-session with correlationIdUnknown, error: ${JSON.stringify(e)}`
            );
            return commit("SET_GLOBAL_ERROR", e);
          }
          dispatch(CLEAR_SESSION, { preserveRedirect: true });
          console.log("Session expired or doesn't exist");
        })
    );
  },
  async [TRY_GET_CLUSTER_FEATURES]({ commit }) {
    const features = await fetchApi.callFunction("get-cluster-features", {
      cached: true
    });
    commit(SET_CLUSTER_FEATURES, features);
    return features;
  },
  [REFRESH_SESSION]({ commit }) {
    return fetchApi
      .callFunction("get-session", { payload: { forceRefresh: true } })
      .then(result => {
        fetchApi.triggerFlushCache();

        commit(SET_SESSION, result);
        return result;
      });
  },
  [UPDATE_ROUTER_QUERY_PARAMS](_ignore, { router, query }) {
    const route = router.currentRoute.value;
    // Vue complains if you router.replace with query with the same query
    if (!_.isEqual(route.query, query)) {
      // save the page query params to local storage (this is used when navigating between pages)
      window.localStorage[`${route.name}-query`] = JSON.stringify(query);
      router.replace({ query });
    }
  },
  ...storeUtils.createAction(
    CONFIGURE_BRANDING,
    ({ state, commit, dispatch }, { brandingEntity }) => {
      if (state.brandingEntity !== brandingEntity) {
        console.log("Branding entity has changed to", brandingEntity);
        commit(SET_BRANDING_ENTITY, brandingEntity);
        commit(SET_BRANDING, null);
        return fetchApi
          .callFunction("get-entity-by-name", {
            payload: { name: brandingEntity, type: "branding" },
            cached: true
          })
          .then(response => {
            const { uuid } = response;
            const { branding } = response.configuration;
            commit(SET_BRANDING, { ...branding, uuid });
            document.title = branding.description;
            dispatch(TRY_SET_FAVICON);
          });
      }
      return Promise.resolve();
    }
  ),
  ...storeUtils.createAction(
    CONFIGURE_UPDATED_BRANDING,
    ({ commit, dispatch }, { brandingEntity }) => {
      commit(SET_BRANDING_ENTITY, brandingEntity);
      return fetchApi
        .callFunction("get-entity-by-name", { payload: { name: brandingEntity, type: "branding" } })
        .then(response => {
          const { uuid } = response;
          const { branding } = response.configuration;
          commit(SET_BRANDING, { ...branding, uuid });
          document.title = `${branding.description} - MSP`;
          dispatch(TRY_SET_FAVICON);
        });
    }
  ),
  ...storeUtils.createAction(FETCH_OPEN_ID_PROVIDERS, ({ commit }) => {
    return fetchApi
      .callFunction("openid-auth-list-providers", { cached: true })
      .then(response => commit(SET_OPEN_ID_PROVIDERS, response));
  }),
  ...storeUtils.createAction(FIND_OPEN_ID_PROVIDER, (_store, { issuer, subscriptionUuid }) => {
    return fetchApi
      .callFunction("openid-auth-find-client", {
        payload: { issuer, subscriptionUuid },
        cached: true
      })
      .then(response => response);
  }),
  ...storeUtils.createAction(
    ACCEPT_HYDRA_LOGIN,
    (
      { dispatch, getters, commit },
      { challenge, userUuid, remember = true, rememberFor = 3600 }
    ) => {
      const selectedRootEntity = getters[SELECTED_ROOT_ENTITY];
      const fnPrefix =
        selectedRootEntity.type === "alchemy-cluster" ? "admin-tech-support-" : "sp-tech-support-";
      return fetchApi
        .callEntityFunction(selectedRootEntity.uuid, "list-candidate-entities", {
          payload: {
            functionNames: [
              `${fnPrefix}accept-login-challenge`,
              `${fnPrefix}accept-consent-challenge`
            ]
          },
          cached: true
        })
        .then(result => {
          const oauthClient = getters[OAUTH_CLIENT];
          const clientId = _.get(oauthClient, "client_id");
          const oauthEntity = _.find(result.entities, e => e.name === clientId);
          commit(SET_SELECTED_OAUTH_ENTITY, oauthEntity);
          return oauthEntity;
        })
        .then(oauthEntity =>
          fetchApi.callEntityFunction(oauthEntity.uuid, `${fnPrefix}accept-login-challenge`, {
            payload: {
              challenge,
              subject: userUuid,
              remember,
              rememberFor
            }
          })
        )
        .then(response => {
          return response.redirect_to;
        })
        .catch(error => {
          return dispatch(REJECT_HYDRA_LOGIN, {
            challenge,
            error: "Login Failed",
            errorDescription: error.response ? error.response.message : error
          });
        });
    }
  ),
  ...storeUtils.createAction(
    REJECT_HYDRA_LOGIN,
    (_store, { challenge, error, errorDescription }) => {
      return fetchApi
        .callFunction("reject-challenge", {
          payload: {
            flow: "login",
            challenge,
            error,
            errorDescription
          }
        })
        .then(response => {
          return response.redirect_to;
        });
    }
  ),
  ...storeUtils.createAction(
    FETCH_HYDRA_LOGIN_CHALLENGE,
    ({ state, getters, commit, dispatch }, challenge) => {
      commit(SET_REDIRECTING, true);

      return (
        fetchApi
          .callFunction("get-challenge", { payload: { flow: "login", challenge } })
          // eslint-disable-next-line consistent-return
          .then(data => {
            commit(SET_OAUTH_CLIENT, data.client);
            commit(SET_LOGIN_CHALLENGE, challenge);
            const isLoggedIn = getters[LOGGED_IN];
            if (isLoggedIn) {
              const { userUuid } = state.session;
              return dispatch(ACCEPT_HYDRA_LOGIN, {
                challenge,
                userUuid
              });
            }
            commit(SET_REDIRECTING, false);
          })
      );
    }
  ),
  ...storeUtils.createAction(
    ACCEPT_HYDRA_CONSENT,
    ({ state, dispatch, commit, getters }, { challenge, remember = true, rememberFor = 3600 }) => {
      const { email } = state.session.user;
      const selectedRootEntity = getters[SELECTED_ROOT_ENTITY];
      const fnPrefix =
        selectedRootEntity.type === "alchemy-cluster" ? "admin-tech-support-" : "sp-tech-support-";
      return fetchApi
        .callEntityFunction(selectedRootEntity.uuid, "list-candidate-entities", {
          payload: {
            functionNames: [
              `${fnPrefix}accept-login-challenge`,
              `${fnPrefix}accept-consent-challenge`
            ]
          },
          cached: true
        })
        .then(result => {
          const oauthClient = getters[OAUTH_CLIENT];
          const clientId = _.get(oauthClient, "client_id");
          const oauthEntity = _.find(result.entities, e => e.name === clientId);
          commit(SET_SELECTED_OAUTH_ENTITY, oauthEntity);
          return oauthEntity;
        })
        .then(oauthEntity =>
          fetchApi.callEntityFunction(oauthEntity.uuid, `${fnPrefix}accept-consent-challenge`, {
            payload: {
              challenge,
              grantScope: ["openid"],
              remember,
              rememberFor,
              email,
              emailVerified: true
            }
          })
        )
        .then(response => {
          return response.redirect_to;
        })
        .catch(error => {
          commit(SET_REDIRECTING, false);
          return dispatch(REJECT_HYDRA_CONSENT, {
            challenge,
            error: "Login Failed",
            errorDescription: error.response ? error.response.message : error
          });
        });
    }
  ),
  ...storeUtils.createAction(
    REJECT_HYDRA_CONSENT,
    (_store, { challenge, error, errorDescription }) => {
      return fetchApi
        .callFunction("reject-challenge", {
          payload: {
            flow: "consent",
            challenge,
            error,
            errorDescription
          }
        })
        .then(response => {
          return response.redirect_to;
        });
    }
  ),
  ...storeUtils.createAction(FETCH_HYDRA_CONSENT_CHALLENGE, ({ commit, dispatch }, challenge) => {
    commit(SET_REDIRECTING, true);
    return fetchApi
      .callFunction("get-challenge", { payload: { flow: "consent", challenge } })
      .then(response => {
        commit(SET_OAUTH_CLIENT, response.client);
        return dispatch(ACCEPT_HYDRA_CONSENT, {
          challenge
        });
      });
  }),
  ...storeUtils.createAction(OPEN_ID_CONNECT, ({ state }, { provider, subscriptionUuid }) => {
    return getProviderRedirectUrl(provider, state, subscriptionUuid).then(response => {
      window.location = response.url;
    });
  }),
  ...storeUtils.createAction(OPEN_ID_AUTHORISE, ({ dispatch, commit }, query) => {
    const state = JSON.parse(query.state);
    dispatch(CONFIGURE_BRANDING, state.browserState.brandingEntity);
    return fetchApi
      .callEntityFunction(state.providerUuid, "openid-auth-complete-login", {
        payload: {
          query
        }
      })
      .then(result => {
        commit(SET_SESSION, result);
        return state.browserState.loginSuccessRedirectTo;
      })
      .catch(() => {
        return `/${state.browserState.brandingEntity}/order-in-progress`;
      });
  }),
  ...storeUtils.createAction(
    TRY_SET_CUSTOM_DATE_RANGE,
    ({ commit }, period) => {
      const { start, end } = period;
      const dates = [new Date(start), new Date(end)];
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(dates[0].getTime())) {
        // eslint-disable-next-line no-throw-literal
        throw { field: "start", message: "Please enter a valid date." };
      }
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(dates[1].getTime())) {
        // eslint-disable-next-line no-throw-literal
        throw { field: "end", message: "Please enter a valid date." };
      }

      const orderedDates = dates.map(d => d.getTime()).sort((a, b) => a - b);
      const startValidated = moment(orderedDates[0]).startOf("day");
      const endValidated = moment(orderedDates[1]).endOf("day");

      commit(SET_REPORT_CUSTOM_DATES, {
        start: startValidated.unix(),
        end: endValidated.unix()
      });
    },
    { formatUserError: e => e }
  ),
  [TRY_SET_FAVICON]({ getters }) {
    const faviconDataUrl = _.get(getters, [BRANDING, "favicon"]);
    if (!faviconDataUrl) {
      return;
    }
    let iconElem = document.querySelector("#customFavicon");
    if (!iconElem) {
      // append new icon element.
      const head = document.getElementsByTagName("head")[0];
      iconElem = document.createElement("link");
      iconElem.rel = "shortcut icon";
      iconElem.id = "customFavicon";
      head.appendChild(iconElem);
    }
    iconElem.href = faviconDataUrl;
  },
  ...storeUtils.createAction(TRY_UNIMPERSONATE, ({ commit }) => {
    return fetchApi.callFunction("unimpersonate-user").then(result => {
      commit(SET_SELECTED_ROOT_ENTITY, null);
      commit(SET_AVAILABLE_ENTITY_TYPES, []);
      commit(SET_SESSION, result);
      commit(SET_REDIRECT, {});

      fetchApi.triggerFlushCache();
      return _.get(result, "data.impersonateConfig", {});
    });
  }),
  ...storeUtils.createAction(SIGN_OUT, ({ dispatch }) => {
    return fetchApi
      .callFunction("delete-session")
      .catch(error => {
        const status = _.get(error, "response.status");
        if (status === 403 || status === 401) {
          console.log(`Received ${status} - Session must have expired already`);
        } else {
          throw error;
        }
      })
      .then(() => dispatch(CLEAR_SESSION));
  }),
  [CLEAR_SESSION]({ commit }, { preserveRedirect = false } = {}) {
    commit(SET_SESSION, {});
    commit(SET_SELECTED_ROOT_ENTITY, null);
    commit(SET_AVAILABLE_ENTITY_TYPES, []);
    if (!preserveRedirect) {
      commit(SET_REDIRECT, {});
    }

    fetchApi.triggerFlushCache();
  },
  ...storeUtils.createAction(TRY_GET_AVAILABLE_OWNER_TYPES, async ({ getters, commit }) => {
    const owningEntity = getters[OWNING_ENTITY];
    const candidateEntityTypes = getters[CANDIDATE_ENTITY_TYPES];

    if (_.isEmpty(owningEntity)) {
      commit(SET_AVAILABLE_OWNER_TYPES, []);
      return;
    }
    const availableEntityTypes = await fetchApi.callEntityFunction(
      owningEntity.uuid,
      "list-available-entity-types",
      { payload: { types: candidateEntityTypes }, cached: true }
    );
    commit(SET_AVAILABLE_OWNER_TYPES, availableEntityTypes);
  }),
  ...storeUtils.createAction(TRY_GET_AVAILABLE_ENTITY_TYPES, async ({ getters, commit }) => {
    const selectedRootEntity = getters[SELECTED_ROOT_ENTITY];
    const candidateEntityTypes = getters[CANDIDATE_ENTITY_TYPES];

    if (!_.isEmpty(selectedRootEntity)) {
      // Retrieve available entity types under the site
      const availableEntityTypes = await fetchApi.callEntityFunction(
        selectedRootEntity.uuid,
        "list-available-entity-types",
        {
          payload: {
            types: candidateEntityTypes
          },
          cached: true
        }
      );
      commit(SET_AVAILABLE_ENTITY_TYPES, availableEntityTypes);
    } else {
      commit(SET_AVAILABLE_ENTITY_TYPES, []);
    }
  }),
  ...storeUtils.createAction(TRY_GET_SUPPORT_LINKS, ({ commit }) => {
    return fetchApi
      .callFunction("get-entity-by-name", {
        payload: {
          name: "alchemy-support-links",
          type: "alchemy-support-links"
        },
        cached: true
      })
      .then(({ configuration }) => {
        commit(SET_SUPPORT_LINKS, configuration);
      });
  }),
  ...storeUtils.createAction(
    TRY_GET_ENTITY_TYPE_MAP,
    async ({ getters }, { entityTypes, target }) => {
      if (!entityTypes || !entityTypes.length) {
        // happens when impersonating.
        console.log("missing entity types for get entity type map.");
        return {};
      }
      const isComposite = getters[IS_COMPOSITE_ENTITY]; // flag.
      const selectedRootEntity = getters[SELECTED_ROOT_ENTITY]; // e.g. site or composite.
      const owningEntity = getters[OWNING_ENTITY]; // e.g. company
      if (owningEntity || selectedRootEntity) {
        return fetchApi.callEntityFunction(
          isComposite ? owningEntity.uuid : selectedRootEntity.uuid,
          "get-entity-type-map",
          {
            payload: {
              entityTypes
            },
            target,
            cached: true
          }
        );
      }
      return Promise.resolve({});
    }
  ),
  ...storeUtils.createAction(TRY_SUBMIT_USER_FEEDBACK, async ({ state }, feedback) => {
    await fetchApi.callEntityFunction(_.get(state, "session.userUuid"), "submit-user-feedback", {
      payload: feedback
    });
  }),
  ...storeUtils.createAction(TRY_GET_ANNOUNCEMENTS, async ({ state, commit }) => {
    const { userUuid } = state.session;
    const announcements = await fetchApi.callEntityFunction(userUuid, "list-announcements", {
      cacheBust: announcementsCacheBust,
      cached: true
    });
    commit(SET_ANNOUNCEMENTS, announcements);
  }),
  ...storeUtils.createAction(TRY_HIDE_ANNOUNCEMENT, async ({ state, commit }, announcement) => {
    const { userUuid } = state.session;
    await fetchApi.callEntityFunction(userUuid, "hide-announcement", {
      payload: {
        announcementUuid: announcement.uuid
      }
    });
    // eslint-disable-next-line no-plusplus
    announcementsCacheBust++;
    commit(CLEAR_ANNOUNCEMENT, announcement);
  }),
  ...storeUtils.createAction(TRY_LIST_CAMERAS, async ({ getters }) => {
    const site = getters[SELECTED_ROOT_ENTITY]; // e.g. site
    const { results, entities } = await fetchApi.callEntityFunction(
      site.uuid,
      "list-meraki-camera-devices",
      {
        cached: true
      }
    );
    return results.map(uuid => entities[uuid]);
  }),
  ...storeUtils.createAction(NAV_TO_SITE, async ({ getters, commit }, { site, router }) => {
    console.log("Navigating to site", _.get(site, "name"));
    commit(SET_SELECTED_ROOT_ENTITY, site);
    router.replace({
      name: getters[ACTIVE_ROUTE],
      params: getters[ROUTER_PARAMS]
    });
  })
};
